import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-view-wrapper" }
const _hoisted_2 = { class: "home-view-container" }
const _hoisted_3 = { class: "page-box" }

import MainTab from '@/components/MainTab.vue';
import HomeDialog from '@/components/dialog/HomeDialog.vue';

import { Haa } from '@haa/fwk-h5/lib/index';
import { ref, onMounted } from 'vue';
import { useAdControl } from '@/hooks/useAdControl';

export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

Haa.util().useLifeCycle();

const { HOME_INTER_SHOWN, TOTAL_NUMBER } = useAdControl();
const showInter = ref<boolean>(false);
const showAd = ref<boolean>(false);
onMounted(() => {
    if (TOTAL_NUMBER.value <= 10) {
        showAd.value = true;
        TOTAL_NUMBER.value += 1;
    }
    if (!HOME_INTER_SHOWN.value) {
        showInter.value = true;
        HOME_INTER_SHOWN.value = true;
        TOTAL_NUMBER.value += 1;
    }
})

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "main-bg" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(MainTab),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_view)
      ])
    ]),
    (showInter.value && showAd.value)
      ? (_openBlock(), _createBlock(HomeDialog, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}
}

})