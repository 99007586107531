import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/icon.png'


const _hoisted_1 = { class: "main-tab-wrap" }
const _hoisted_2 = { class: "main-title" }
const _hoisted_3 = { class: "main-tab-box" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]

import router from '@/router';
import { ref, reactive, watch } from 'vue';
import { useRoute } from 'vue-router';

import { Haa, OeReportType, OeReportSubType } from '@haa/fwk-h5/lib/index';

export default /*@__PURE__*/_defineComponent({
  __name: 'MainTab',
  setup(__props) {

const { oeReport } = Haa.util().useOeReport();
const appName = APP_NAME;

const route = useRoute();
const active = ref<number>(0);
const tabList = reactive([
    { icon: require('@/assets/images/tabbar/tab_topic.png'), icon_s: require('@/assets/images/tabbar/tab_topic_s.png'), text: '测一测', path: '/topic' },
    { icon: require('@/assets/images/tabbar/tab_discover.png'), icon_s: require('@/assets/images/tabbar/tab_discover_s.png'), text: '发现', path: '/discover' },
    { icon: require('@/assets/images/tabbar/tab_hot.png'), icon_s: require('@/assets/images/tabbar/tab_hot_s.png'), text: '热门榜', path: '/hot' },
    { icon: require('@/assets/images/tabbar/tab_rank.png'), icon_s: require('@/assets/images/tabbar/tab_rank_s.png'), text: '排行榜', path: '/rank' },
])
tabList.forEach((item, index) => {
    if (item.path == route.path) active.value = index;
})
watch(
    () => route.path,
    () => {
        tabList.forEach((item, index) => {
            if (item.path == route.path) active.value = index;
        })
    }
);

const handleClick = (index: number) => {
    active.value = index;
    let tab = tabList[active.value];
    oeReport(OeReportType.Action, OeReportSubType.TabClick, tabList[active.value].text);
    router.replace({ path: tab.path });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
      _createElementVNode("span", null, _toDisplayString(_unref(appName)), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabList, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "tab-item",
          onClick: ($event: any) => (handleClick(index))
        }, [
          _createElementVNode("img", {
            src: active.value == index ? item.icon_s : item.icon
          }, null, 8, _hoisted_5)
        ], 8, _hoisted_4))
      }), 256))
    ])
  ]))
}
}

})