import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/assets/images/common/title.png'
import _imports_1 from '@/assets/images/common/hot.png'
import _imports_2 from '@/assets/images/common/play.png'


const _hoisted_1 = {
  key: 0,
  class: "dialog-home-container"
}
const _hoisted_2 = { class: "dialog-info" }
const _hoisted_3 = { class: "preview-box" }
const _hoisted_4 = { class: "info-box" }
const _hoisted_5 = { class: "info-desc" }
const _hoisted_6 = { class: "info-tag" }

import { ref } from 'vue';
import { Haa } from '@haa/fwk-h5/lib/index';
import bus from '@haa/fwk-h5/lib/bus';
import { useFunplay } from '@/hooks/useFunplay';

export default /*@__PURE__*/_defineComponent({
  __name: 'HomeDialog',
  setup(__props) {

const { strategy } = Haa.util().useStrategy();
/** 展示类型，-1：不展示，0：官方插屏，1：对话框插屏 */
const showType = ref<number>(-1);
const setShowType = () => {
    if (strategy.h5_ad_space_config.Home_dialog) {
        showType.value = 1;
    } else if (strategy.h5_ad_space_config.Home_inter) {
        showType.value = 0;
    }
}
if (strategy.status != 0) {
    setShowType();
} else {
    bus.on('strategy', setShowType);
}

const { funplayHotList } = useFunplay();
const random = ref<number>(Math.floor(Math.random() * 10));

const handleClose = () => {
    showType.value = -1;
}

return (_ctx: any,_cache: any) => {
  const _component_van_image = _resolveComponent("van-image")!

  return (_openBlock(), _createElementBlock("div", {
    class: "dialog-home-wrap",
    style: _normalizeStyle({'width': showType.value == 1 ? '100%': '0', 'height': showType.value == 1 ? '100%' : '0'})
  }, [
    (showType.value == 1 && _unref(funplayHotList).data[random.value])
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_van_image, {
                src: _unref(funplayHotList).data[random.value].prev_bg_url,
                height: "100%",
                radius: "10px"
              }, null, 8, ["src"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "info-title" }, [
                _createElementVNode("img", { src: _imports_0 }),
                _createElementVNode("span", null, "测一测推荐")
              ], -1)),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(funplayHotList).data[random.value].desc), 1),
              _createElementVNode("div", _hoisted_6, [
                _cache[0] || (_cache[0] = _createElementVNode("img", { src: _imports_1 }, null, -1)),
                _createElementVNode("span", null, _toDisplayString(_unref(funplayHotList).data[random.value].view_num) + "人玩过", 1)
              ]),
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "play-box" }, [
                _createElementVNode("img", { src: _imports_2 })
              ], -1))
            ])
          ]),
          _createElementVNode("div", {
            class: "click-btn",
            onClick: handleClose
          }, "关闭")
        ]))
      : _createCommentVNode("", true)
  ], 4))
}
}

})