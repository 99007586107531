<script setup lang="ts">
import { ref } from 'vue';
import { Haa } from '@haa/fwk-h5/lib/index';
import bus from '@haa/fwk-h5/lib/bus';
const { strategy } = Haa.util().useStrategy();
/** 展示类型，-1：不展示，0：官方插屏，1：对话框插屏 */
const showType = ref<number>(-1);
const setShowType = () => {
    if (strategy.h5_ad_space_config.Home_dialog) {
        showType.value = 1;
    } else if (strategy.h5_ad_space_config.Home_inter) {
        showType.value = 0;
    }
}
if (strategy.status != 0) {
    setShowType();
} else {
    bus.on('strategy', setShowType);
}

import { useFunplay } from '@/hooks/useFunplay';
const { funplayHotList } = useFunplay();
const random = ref<number>(Math.floor(Math.random() * 10));

const handleClose = () => {
    showType.value = -1;
}
</script>

<template>
    <div class="dialog-home-wrap" :style="{'width': showType == 1 ? '100%': '0', 'height': showType == 1 ? '100%' : '0'}">
        <!--<wap-inter v-if="showType == 0" space-key="Home_inter"></wap-inter>-->
        <div v-if="showType == 1 && funplayHotList.data[random]" class="dialog-home-container">
            <div class="dialog-info">
                <div class="preview-box">
                    <van-image :src="funplayHotList.data[random].prev_bg_url" height="100%" radius="10px"></van-image>
                </div>
                <div class="info-box">
                    <div class="info-title">
                        <img src="@/assets/images/common/title.png" />
                        <span>测一测推荐</span>
                    </div>
                    <div class="info-desc">{{ funplayHotList.data[random].desc }}</div>
                    <div class="info-tag">
                        <img src="@/assets/images/common/hot.png" />
                        <span>{{ funplayHotList.data[random].view_num }}人玩过</span>
                    </div>
                    <div class="play-box">
                        <img src="@/assets/images/common/play.png" />
                    </div>
                    <!-- <div class="btn">立即开测</div> -->
                </div>
            </div>
            <div class="click-btn" @click="handleClose">关闭</div>
            <!--<div class="ad-wrap">
                <wap-native space-key="Home_dialog"></wap-native>
            </div>-->
        </div>
    </div>
</template>

<style scoped lang="less">
.dialog-home-wrap {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .dialog-home-container {
        width: 690px;
        .dialog-info {
            background-color: #fff;
            padding: 20px;
            border-radius: 20px;
            display: flex;
            justify-content: space-between;
            .preview-box {
                width: 238px;
                height: 506px;
                margin-right: 30px;
            }
            .info-box {
                flex: 1;
                position: relative;
                .info-title {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    img {
                        width: 52px;
                        height: 52px;
                        margin-right: 10px;
                    }
                    span {
                        font-size: 44px;
                        color: #844A17;
                        font-weight: 600;
                    }
                }
                .info-desc {
                    font-size: 24px;
                    color: #666666;
                    margin-bottom: 20px;
                }
                .info-tag {
                    display: flex;
                    height: 40px;
                    width: 200px;
                    border-radius: 8px;
                    background-color: #FFEEC9;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                    }
                    span {
                        font-size: 20px;
                        color: #000000;
                    }
                }
                .play-box {
                    width: 270px;
                    height: 234px;
                    position: absolute;
                    bottom: 0;left: 50px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .btn {
                    width: 100%;
                    height: 82px;
                    border-radius: 82px;
                    line-height: 82px;
                    text-align: center;
                    position: absolute;
                    bottom: 0;left: 0;
                    background: linear-gradient(283.2deg, #FF7029 32.26%, #FFD103 120.51%);
                    font-size: 30px;
                    color: #fff;
                }
            }
        }
        .click-btn {
            height: 82px;
            border-radius: 82px;
            line-height: 82px;
            background-color: #fff;
            text-align: center;
            font-size: 30px;
            color: #FF7836;
            margin: 20px 0;
            font-weight: 600;
        }
        .ad-wrap {
            background-color: #fff;
            border-radius: 20px;
            overflow: hidden;
        }
    }
}
</style>
