import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/common/guide_main.png'
import _imports_1 from '@/assets/images/common/guide_op_1.png'
import _imports_2 from '@/assets/images/common/guide_op_2.png'
import _imports_3 from '@/assets/images/common/guide_op_3.png'
import _imports_4 from '@/assets/images/common/guide_btn.png'


const _hoisted_1 = { class: "guide-wrapper" }
const _hoisted_2 = { class: "fixed-bottom" }

import IcpComp from '@/components/IcpComp.vue';

import { Haa } from '@haa/fwk-h5/lib/index';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'Guide',
  setup(__props) {

Haa.util().useLifeCycle();

const router = useRouter();
const handleClick = () => {
    router.replace({ path: '/topic' });
}

return (_ctx: any,_cache: any) => {
  const _directive_click = _resolveDirective("click")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createStaticVNode("<img class=\"main\" src=\"" + _imports_0 + "\" data-v-84a41e8a><img class=\"option animate__animated animate__backInLeft\" src=\"" + _imports_1 + "\" data-v-84a41e8a><img class=\"option option-2 animate__animated animate__backInRight animate__delay-1s\" src=\"" + _imports_2 + "\" data-v-84a41e8a><img class=\"option animate__animated animate__backInUp animate__delay-2s\" src=\"" + _imports_3 + "\" data-v-84a41e8a><div class=\"placeholder\" data-v-84a41e8a></div>", 5)),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("img", {
        onClick: handleClick,
        class: "click-btn animate__animated animate__heartBeat animate__infinite",
        src: _imports_4
      }, null, 512), [
        [
          _directive_click,
          void 0,
          "oeReport",
          { btn: true }
        ]
      ]),
      _createVNode(IcpComp)
    ])
  ]))
}
}

})