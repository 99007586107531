<script setup lang="ts">
import IcpComp from '@/components/IcpComp.vue';

import { Haa } from '@haa/fwk-h5/lib/index';
Haa.util().useLifeCycle();

import { useRouter } from 'vue-router';

const router = useRouter();
const handleClick = () => {
    router.replace({ path: '/topic' });
}
</script>

<template>
    <div class="guide-wrapper">
        <img class="main" src="@/assets/images/common/guide_main.png" />
        <img class="option animate__animated animate__backInLeft" src="@/assets/images/common/guide_op_1.png" />
        <img class="option option-2 animate__animated animate__backInRight animate__delay-1s" src="@/assets/images/common/guide_op_2.png" />
        <img class="option animate__animated animate__backInUp animate__delay-2s" src="@/assets/images/common/guide_op_3.png" />
        <div class="placeholder"></div>
        <div class="fixed-bottom">
            <img v-click:oeReport.btn @click="handleClick" class="click-btn animate__animated animate__heartBeat animate__infinite" src="@/assets/images/common/guide_btn.png" />
            <icp-comp></icp-comp>
        </div>
    </div>
</template>

<style scoped lang="less">
.guide-wrapper {
    height: 100vh;
    overflow: auto;
    background-image: url('@/assets/images/common/guide_bg.png');
    background-size: 100% 100%;
    img {
        vertical-align: middle;
    }
    .main {
        width: 100%;
    }
    .option {
        width: 534px;
        height: 118px;
        margin-left: 50px;
        margin-bottom: 20px;
    }
    .option-2 {
        margin-left: 130px;
    }
    .placeholder {
        height: 4rem;
    }
    .fixed-bottom {
        width: 100%;
        position: absolute;
        bottom: 0;left: 0;
        background-color: #EFFEFF;
    }
    .click-btn {
        width: 490px;
        height: 110px;
        margin-left: 130px;
        margin-top: 50px;
    }
}
</style>
