<script setup lang="ts">
import MainTab from '@/components/MainTab.vue';
import HomeDialog from '@/components/dialog/HomeDialog.vue';

import { Haa } from '@haa/fwk-h5/lib/index';
Haa.util().useLifeCycle();

import { ref, onMounted } from 'vue';
import { useAdControl } from '@/hooks/useAdControl';
const { HOME_INTER_SHOWN, TOTAL_NUMBER } = useAdControl();
const showInter = ref<boolean>(false);
const showAd = ref<boolean>(false);
onMounted(() => {
    if (TOTAL_NUMBER.value <= 10) {
        showAd.value = true;
        TOTAL_NUMBER.value += 1;
    }
    if (!HOME_INTER_SHOWN.value) {
        showInter.value = true;
        HOME_INTER_SHOWN.value = true;
        TOTAL_NUMBER.value += 1;
    }
})
</script>

<template>
    <div class="home-view-wrapper">
        <div class="main-bg"></div>
        <div class="home-view-container">
            <main-tab></main-tab>

            <div class="page-box">
                <router-view />
            </div>
        </div>

        <home-dialog v-if="showInter && showAd"></home-dialog>

        <!--<wap-banner v-if="showAd" space-key="Home_banner"></wap-banner>-->
    </div>
</template>

<style scoped lang="less">
.home-view-wrapper {
    height: 100vh;
    background-color: #f5f5f5;
    position: relative;
    .main-bg {
        width: 100%;
        height: 420px;
        position: absolute;
        left: 0;top: 0;
        background: linear-gradient(180deg, #F7DEB8 0%, rgba(244, 240, 209, 0) 100%);
        z-index: 1;
    }
    .home-view-container {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        box-sizing: border-box;
        padding: 0 30px;
        display: flex;
        flex-direction: column;
        .page-box {
            flex: 1;
            overflow-y: auto;
        }
    }
}
</style>
